import Link from "next/link";
import PoweredBy from "../components/PoweredBy";

export default function FourOhFour() {
  return (
    <div className="flex items-center justify-center vh-100 flex-column bg-black silver">
      <h3 className="f-headline lh-solid">404</h3>
      <h1 className="f-3">Page Not Found</h1>
      <PoweredBy darkMode={true} />
    </div>
  );
}
